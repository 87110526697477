import { type VariantProps, cva } from 'class-variance-authority';
import type { PropsWithChildren } from 'react';

const cardBadge = cva(['absolute', 'z-0'], {
  variants: {
    spacing: {
      sm: [],
      md: [],
      lg: [],
    },
    position: {
      'top-left': [],
      'top-right': [],
      'bottom-left': [],
      'bottom-right': [],
    },
  },
  compoundVariants: [
    {
      spacing: 'sm',
      position: 'top-left',
      class: ['top-1', 'left-1'],
    },
    {
      spacing: 'md',
      position: 'top-left',
      class: ['top-2', 'left-2'],
    },
    {
      spacing: 'lg',
      position: 'top-left',
      class: ['top-3', 'left-3'],
    },
    {
      spacing: 'sm',
      position: 'top-right',
      class: ['top-1', 'right-1'],
    },
    {
      spacing: 'md',
      position: 'top-right',
      class: ['top-2', 'right-2'],
    },
    {
      spacing: 'lg',
      position: 'top-right',
      class: ['top-3', 'right-3'],
    },
    {
      spacing: 'sm',
      position: 'bottom-left',
      class: ['bottom-1', 'left-1'],
    },
    {
      spacing: 'md',
      position: 'bottom-left',
      class: ['bottom-2', 'left-2'],
    },
    {
      spacing: 'lg',
      position: 'bottom-left',
      class: ['bottom-3', 'left-3'],
    },
    {
      spacing: 'sm',
      position: 'bottom-right',
      class: ['bottom-1', 'right-1'],
    },
    {
      spacing: 'md',
      position: 'bottom-right',
      class: ['bottom-2', 'right-2'],
    },
    {
      spacing: 'lg',
      position: 'bottom-right',
      class: ['bottom-3', 'right-3'],
    },
  ],
  defaultVariants: {
    spacing: 'md',
    position: 'bottom-right',
  },
});

export type CardBadgeProps = VariantProps<typeof cardBadge> & PropsWithChildren;

export const CardBadge = ({ position, spacing, children }: CardBadgeProps) => {
  return <div className={cardBadge({ spacing, position })}>{children}</div>;
};
